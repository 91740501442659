import Strapi from './Strapi';

const collection = 'system-configs';

let cache = [];

const getSystemConfig = async (clearCache = false) => {
    if (!clearCache && cache) return cache;

    const result = await Strapi.get(`${collection}`);
    cache = result;

    // Assuming result is an array
    return Array.isArray(result) ? result : [];
}

const getSystemConfigByKey = async (key, clearCache = false) => {
    // Check if the data is already cached
    if (!clearCache && cache.length > 0) {
        return cache.find(item => item.key === key);
    }

    // Fetch the system configuration data
    const response = await Strapi.get(`${collection}`);

    // Ensure the response is an array before caching
    if (Array.isArray(response)) {
        cache = response;
        return cache.find(item => item.key === key);
    } else {
        return [];
    }

    // Find and return the configuration data by the given key

}

const editSystemConfigByID = (id, body) => Strapi.put(`${collection}/${id}`, JSON.stringify(body));


export { getSystemConfig, getSystemConfigByKey,editSystemConfigByID };
