import Strapi from './Strapi';
import qs from 'qs';

const collection = 'sites-and-groups';
// const getProject = (companyId, query) => Strapi.get(`${collection}?company=${companyId}&${qs.stringify(query)}`);
const getSitesAndGroupsByParent = (companyId,parentNmae, query) => Strapi.get(`${collection}?company=${companyId}&parent.name=${parentNmae}&${qs.stringify(query)}`);
const getSitesAndGroups = (companyId, query) => Strapi.get(`${collection}?company=${companyId}&${qs.stringify(query)}`);
const getSitesAndGroupsTree = (query) => Strapi.get(`${collection}/tree?${qs.stringify(query)}`);
const getSitesAndGroupsChildrenId = (id, query) => Strapi.get(`${collection}/${id}/sites?${qs.stringify(query)}`);
const createSitesAndGroups = (body) => Strapi.post(`${collection}`, JSON.stringify(body));
const editSitesAndGroups = (id, body) => Strapi.put(`${collection}/${id}`, JSON.stringify(body));
const editSitesAndGroupsStatus = (id, status, companyId) => Strapi.put(`${collection}/${id}/status/${status}?companyId=${companyId}`);
const delSitesAndGroups = (id) => Strapi.delete(`${collection}/${id}`);

export {
  getSitesAndGroupsByParent,
  getSitesAndGroups,
  getSitesAndGroupsTree,
  getSitesAndGroupsChildrenId,
  createSitesAndGroups,
  editSitesAndGroups,
  editSitesAndGroupsStatus,
  delSitesAndGroups,
};
