import React, { useState, useEffect, lazy } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import AccessibleNavigationAnnouncer from './components/AccessibleNavigationAnnouncer';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { getTranslations } from './services';

const Layout = lazy(() => import('./containers/Layout'));
const PublicFormFilling = lazy(() => import('./pages/FormFilling/Public'));
const Login = lazy(() => import('./pages/Login'));
const CreateAccount = lazy(() => import('./pages/CreateAccount'));
const ForgotPassword = lazy(() => import('./pages/ForgotPassword'));

function App() {
  const [loadingLanguage, setLoadingLanguage] = useState(true);
  const { t, i18n } = useTranslation();

  const loadTranslation = async () => {
    try {
      const cachedTranslation = localStorage.getItem('cached_translation') ;
      let allTranslations = JSON.parse(cachedTranslation) || await getTranslations();

      if (!cachedTranslation) {
        localStorage.setItem('cached_translation', JSON.stringify(allTranslations));
      }

      for (let i = 0; i < allTranslations.length; i++) {
        const code = allTranslations[i].language_code;
        const data = allTranslations[i].data;
        i18next.addResourceBundle(code, 'translation', data, true, true);
      }
    } catch(e) {
      console.log(e)
    } finally {
      setLoadingLanguage(false);
    }
  }

  useEffect(() => {
    loadTranslation();
  }, [])

  if (loadingLanguage) {
    return (
      <div>
        <h1 className="text-xs font-bold m-2">Loading lanugage pack</h1>
      </div>
    )
  }

  return (
    <>
      <Router>
        <AccessibleNavigationAnnouncer />
        <Switch>
          <Route path="/form-public/:id" component={PublicFormFilling} />
          <Route path="/login" component={Login} />
          <Route path="/create-account" component={CreateAccount} />
          <Route path="/forgot-password" component={ForgotPassword} />

          {/* Place new routes over this */}
          <Route path="/app" component={Layout} />
          {/* If you have an index page, you can remothis Redirect */}
          <Redirect exact from="/" to="/login" />
        </Switch>
      </Router>
    </>
  );
}

export default App;
