import Strapi from './Strapi';
import qs from 'qs';

const collection = 'indicators';

const getIndicators = (query) => {
  const parameter = qs.stringify(query);
  return Strapi.get(`${collection}?${parameter}`);
}
const getIndicatorsCount = (query) => {
  const parameter = qs.stringify(query);
  return Strapi.get(`${collection}/count?${parameter}`);
}
const getIndicatorCats = (query) => Strapi.get(`${collection}/categories?${qs.stringify(query)}`);
const getIndicatorTags = () => Strapi.get(`${collection}/tags`);
const createIndicator = (body) => Strapi.post(`${collection}`, JSON.stringify(body));
const editIndicator = (id, body) => Strapi.put(`${collection}/${id}`, JSON.stringify(body));
const delIndicator = (id) => Strapi.delete(`${collection}/${id}`);

export {
  getIndicators,
  getIndicatorsCount,
  getIndicatorCats,
  getIndicatorTags,
  createIndicator,
  delIndicator,
  editIndicator,
};
