import Strapi from './Strapi';
import qs from 'qs';

const collection = 'comments';

const getCommentsByFormId = (query) => Strapi.get(`${collection}?${qs.stringify(query)}`);
const addComment = (body) => Strapi.post(`${collection}`, JSON.stringify(body));
const solveComment = (id, solved) => Strapi.put(`${collection}/${id}`, JSON.stringify({ solved }));

export {
  getCommentsByFormId,
  addComment,
  solveComment,
};
