import Strapi from './Strapi';
import qs from 'qs';

const collection = 'dashboards';

const getDashBoardData = (query) => {
  const result = Strapi.get(`${collection}/mini-chart?${query}`);
  return result;
}

const getOutcomeByIndicator = (companyId, indicatorId, masterSiteAndGroup, year) => {
  let url = `${collection}/calculation/${companyId}/${indicatorId}?parent=${masterSiteAndGroup}`;
  if (year) url += `&year=${year}`;
  return Strapi.get(url);
}

export {
  getDashBoardData,
  getOutcomeByIndicator,
};
