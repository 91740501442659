const url = process.env.REACT_APP_API_URL;

const Strapi = {
  authorizationHeader: (type = 'json') => {
    const header = {};
    const jwt = localStorage.jwt;
    if (jwt) header.Authorization = `Bearer ${jwt}`;
    if (type === 'json') header['Content-Type'] = 'application/json';
    return header;
  },
  call: async (method, collection, body = null) => {
    const type = typeof body === 'object' ? 'files' : 'json';

    const options = {
      method,
      headers: {
        ...Strapi.authorizationHeader(type),
      },
    };

    if (method !== 'GET' && body !== null) options.body = body;

    const response = await fetch(`${url}/${collection}`, options);
    const result = await response.json();

    if (response.status >= 400) {
      const allMessage =
        typeof (result.message) === 'string' ? [result.message]
        : result.message?.map(
          (layer1) => layer1.messages?.map((layer2) => layer2?.message || []) || []
        ) || [];
      throw new Error(`${result.statusCode}: ${result.error}, ${allMessage.join(', ')}`);
    }

    return result;
  },
  get: (collection) => {
    return Strapi.call('GET', collection);
  },
  post: (collection, body, config) => {
    return Strapi.call('POST', collection, body);
  },
  put: (collection, body) => {
    return Strapi.call('PUT', collection, body);
  },
  delete: (collection, body) => {
    return Strapi.call('DELETE', collection, body);
  },
  upload: (files) => {
    const data = new FormData();
    files.forEach((file) => data.append('files', file));
    return Strapi.call('POST', 'upload', data);
  },
};

export default Strapi;
