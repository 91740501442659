import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { getCurrentUserInfo } from '../services';

import TRANSLATIONS_ZH from "./zh/translations.json";
import TRANSLATIONS_EN from "./en/translations.json";
import TRANSLATIONS_CN from "./cn/translations.json";
import TRANSLATION_COMPANY from './translationByCompany.json';

const DETECTION_OPTIONS = {
  order: ['querystring', 'cookie', 'localStorage', 'sessionStorage', , 'htmlTag', 'path', 'subdomain', 'navigator'],

  lookupQuerystring: 'lng',
  lookupCookie: 'i18next',
  lookupLocalStorage: 'i18nextLng',
  lookupSessionStorage: 'i18nextLng',
  lookupFromPathIndex: 0,
  lookupFromSubdomainIndex: 0,

  caches: ['localStorage', 'cookie'],
  excludeCacheFor: ['cimode'],
}

const availableLanguages = ['en', 'zh', 'cn'];

i18n
  .use(Backend)
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    detection: DETECTION_OPTIONS,
    fallbackLng: 'en',
    debug: false,
    resources: {
      en: {
        translation: TRANSLATIONS_EN
      },
      zh: {
        translation: TRANSLATIONS_ZH
      },
      cn: {
        translation: TRANSLATIONS_CN
      }
    },
    interpolation: {
      escapeValue: false,
    },
    supportedLngs: availableLanguages,
    keySeparator: false,
    nsSeparator: false // We have used : on type.
  });

// For some company need to replace some text before translation
// We use i18n.t function directly as it'a already applied on 
// most of the text. After text replacement, keep the translation.
i18n.oldT = i18n.t;
i18n.t = function(...arg) {
  try {
    const user = getCurrentUserInfo();
    const companyId = user?.company?.id;
    if (!companyId) throw null;

    arg[0] = TRANSLATION_COMPANY?.[companyId]?.[arg[0]] || arg[0];
    return i18n.oldT(...arg)
  } catch(e) {
    return i18n.oldT(...arg)
  }
}

export default i18n;