const url = process.env.REACT_APP_API_URL;

async function getConstantLocations() {
  try {
    const response = await fetch(`${url}/constant-locations?_sort=rank`, {
      headers: {
        Authorization: `bearer ${localStorage.jwt}`,
      },
    });

    const result = await response.json();
    if (response.status >= 400) throw result;
    return result;
  } catch (e) {
    console.log('Login error', e);
    throw e;
  }
}

async function createConstantLocation(body) {
  try {
    const response = await fetch(`${url}/constant-locations?_sort=rank`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `bearer ${localStorage.jwt}`,
      },
      body: JSON.stringify(body),
    });

    const result = await response.json();
    if (response.status >= 400) throw result;
    return result;
  } catch (e) {
    console.log('Login error', e);
    throw e;
  }
}

async function editConstantLocation(id, body) {
  try {
    const response = await fetch(`${url}/constant-locations/${id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `bearer ${localStorage.jwt}`,
      },
      body: JSON.stringify(body),
    });

    const result = await response.json();
    if (response.status >= 400) throw result;
    return result;
  } catch (e) {
    console.log('Login error', e);
    throw e;
  }
}

async function delConstantLocation(id) {
  try {
    const response = await fetch(`${url}/constant-locations/${id}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `bearer ${localStorage.jwt}`,
      },
    });

    const result = await response.json();
    if (response.status >= 400) throw result;
    return result;
  } catch (e) {
    console.log('Del company error error', e);
    throw e;
  }
}

export { getConstantLocations, createConstantLocation, delConstantLocation, editConstantLocation };
