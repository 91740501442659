import Strapi from './Strapi';
import qs from 'qs';

const url = process.env.REACT_APP_API_URL;

const collection = 'forms';
const getAllForms = (query) => Strapi.get(`${collection}`);
const getFormsBySite = (id) => Strapi.get(`${collection}?&site._id=${id}`);
const getForms = (query) => Strapi.get(`${collection}?${qs.stringify(query)}`);
const getFormCount = (query) => Strapi.get(`${collection}/count?${qs.stringify(query)}`);
const getFormById = (id) => Strapi.get(`${collection}/${id}`);
const getFormByParentId = (id) => Strapi.get(`${collection}/?parent=${id}&status_ne=completed&_sort=published_at ASC`);
const getFormsTemplate = (query) => Strapi.get(`${collection}?template_eq=true&${qs.stringify(query)}`);
const getFormsExistingYears = (query) => Strapi.get(`${collection}/years?${qs.stringify(query)}`);
const getFormsExistingProject = (query) => Strapi.get(`${collection}/names?${qs.stringify(query)}`);
const getSitesAndGroups = (companyId, query) => Strapi.get(`${collection}?company=${companyId}&${qs.stringify(query)}`);
const getFormsExistingSurvey = (query) => Strapi.get(`${collection}/survey?${qs.stringify(query)}`);
const getFormsByCompanyId = (id, query) => Strapi.get(`${collection}?companies=${id}&${qs.stringify(query)}`);
const getIncompleteFormsByCompanyId = (id, query) => Strapi.get(`${collection}?completion_rate_cache_ne=1&companies=${id}&${qs.stringify(query)}`);
const getFormsByCompanyIdSiteName = (id, siteName, query) => Strapi.get(`${collection}?companies=${id}&site.name_contains=${siteName}&${qs.stringify(query)}`);
const getFormsErrors = (id) => Strapi.get(`${collection}/${id}/errors`);
const createForm = (body) => Strapi.post(`${collection}`, JSON.stringify(body));
const editForm = (id, body) => Strapi.put(`${collection}/${id}`, JSON.stringify(body));
const editFormStatus = (id, status) => Strapi.put(`${collection}/${id}/status/${status}`);
const updateFormConstant = (id,) => Strapi.put(`${collection}/${id}/constant`);
const delForm = (id) => Strapi.delete(`${collection}/${id}`);
const exportFormURL = (id, type) => `${url}/${collection}/${id}/export?type=${type}`;
const getCompletionRate = (query) => Strapi.get(`${collection}/completion?${qs.stringify(query)}`);
const dialOutbound = (body) => Strapi.post(`${collection}/dialOutbound`,JSON.stringify(body));

export {
  getAllForms,
  getFormsBySite,
  getForms,
  getFormCount,
  getFormById,
  getFormByParentId,
  getFormsTemplate,
  getFormsExistingYears,
  getFormsExistingProject,
  getFormsExistingSurvey,
  getFormsByCompanyId,
  getIncompleteFormsByCompanyId,
  getFormsByCompanyIdSiteName,
  getFormsErrors,
  createForm,
  delForm,
  editForm,
  editFormStatus,
  updateFormConstant,
  exportFormURL,
  getCompletionRate,
  dialOutbound
};
