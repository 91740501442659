import Strapi from './Strapi';

const collection = 'payments';

const getPayments = () => Strapi.get(`${collection}`);
const getPaymentsByCompanyId = (id) => Strapi.get(`${collection}?company=${id}`);
const createPayment = (body) => Strapi.post(`${collection}`, JSON.stringify(body));
const editPayment = (id, body) => Strapi.put(`${collection}/${id}`, JSON.stringify(body));
const delPayment = (id) => Strapi.delete(`${collection}/${id}`);

export { getPayments, getPaymentsByCompanyId, createPayment, delPayment, editPayment };
